import { PricingPage } from "@superops/superops-pricing"
import axios from "axios"
import { graphql, StaticQuery } from "gatsby"
import parse from "html-react-parser"
import "rc-slider/assets/index.css"
import React, { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import CustomAccordion from "../../components/common/accordian"
import CTA from "../../components/common/CTA"
import Frame from "../../components/common/frame"
import DoubleNavbar from "../../components/DoubleNavbar"
import useApiData from "../../components/useApiData"
import CTAThankYou from "../../components/utility/cta_thank_you"
import { useIntercomShow } from "../../hooks/useIntercomShow"
import "../../styles/component/faq.scss"
import "../../styles/component/pricing-faq.scss"
import "../../styles/pricing-new.scss"

export const currencyList = [
  {
    currencyCode: "AUD",
    value: "AUD",
    label: "$ - AUD - Australian Dollar",
  },
  {
    currencyCode: "EUR",
    value: "EUR",
    label: "€ - EUR - Euro",
  },
  {
    currencyCode: "GBP",
    value: "GBP",
    label: "£ - GBP - Pound Sterling",
  },
  {
    currencyCode: "USD",
    value: "USD",
    label: "$ - USD - US Dollar",
  },
]

function PricingPackage2024() {
  useIntercomShow(".faq .accordion .card-body p code, #chatId2")

  const { apiData } = useApiData()
  const [currency, setCurrency] = useState("USD")

  const [actions] = useState({
    onCurrencyChange: curr => {
      setCurrency(curr?.currencyCode)
    },
  })

  const getIpStackData = async () => {
    try {
      const response = await axios.get(process.env.IP_STACK_API)
      if (
        response.data.continent_name === "Europe" &&
        response.data.country_name !== "United Kingdom"
      ) {
        setCurrency("EUR")
      } else if (response.data.country_name === "United Kingdom") {
        setCurrency("GBP")
      } else if (
        response.data.country_code === "AU" ||
        response.data.country_name === "Australia"
      ) {
        setCurrency("AUD")
      }
    } catch (error) {
      console.log("Error in getting IP Stack data", error)
    }
  }

  useEffect(() => {
    getIpStackData()
  }, [])

  return (
    <>
      <StaticQuery
        query={graphql`
          query contentPricingNew2024 {
            SuperOps {
              pages(where: { title: "Pricing" }) {
                title
                featureGroup
                pageBlock {
                  id
                  content {
                    html
                    text
                  }
                }
                navigationBlock {
                  name
                  slug
                  isDropdown
                }
                seo {
                  title
                  description
                  keywords
                  image {
                    url
                  }
                }
                ctaBoxes {
                  type
                  theme
                  heading {
                    html
                  }
                  description {
                    html
                  }
                  primaryButtonText
                  primaryButtonLink
                  secondaryButtonText
                  secondaryButtonLink
                }
              }
              newPricingLists(orderBy: featureOrder_ASC) {
                featureGroup
                featureName
                newFeature
                plan1
                plan2
                plan3
                plan4
                tooltip
                texts {
                  html
                }
              }
              seo(where: { id: "cm0ds4hf17iu008ln4uep6k77" }) {
                title
                description
                keywords
                image {
                  url
                }
              }
              faqs(where: { page: "Pricing" }) {
                category
                question
                answerrt {
                  html
                }
              }
            }
          }
        `}
        render={data => (
          <div className="pricing-wrap">
            {data.SuperOps.pages.map(page => {
              const {
                pageBlock,
                navigationBlock,
                ctaBoxes,
                featureGroup,
              } = page
              const seo = data.SuperOps.seo
              return (
                <>
                  <Frame seo={seo} page="Home" bigFooter>
                    <DoubleNavbar navType="double-nav" />

                    <PricingPage
                      isWebsite
                      currentCurrency={currency}
                      purchasePageMeta={apiData.purchasePageMeta}
                      endpointsPrice={apiData.addons}
                      availablePlans={apiData.plans}
                      currencyList={currencyList}
                      actions={actions}
                    />

                    <div className="cta-black-red-stripes">
                      <CTA
                        data={ctaBoxes}
                        containerClass
                        lgLeft={6}
                        lgRight={4}
                        newButton
                        isSecondaryBtn
                        newDesign
                      />
                    </div>

                    <div className="main">
                      <div className="faq super-pricing-faq mt0">
                        <Container className="Layout-container">
                          <Row className="justify-content-between">
                            <Col lg={4}>
                              {parse(pageBlock[9].content.html)}
                              {parse(pageBlock[10].content.html)}
                            </Col>
                            <Col lg={8}>
                              <div className="section">
                                <CustomAccordion items={data.SuperOps.faqs} />
                                <Row>
                                  <Col lg={5}>
                                    <h5 className="pricing-faq-title fw-bold font-zodiak">
                                      Have more questions?
                                    </h5>
                                  </Col>
                                  <Col lg={7}>
                                    <span className="p16">
                                      <p className="pricing-faq-desc">
                                        We're available round the clock to
                                        answer your questions and help you find
                                        the right plan.{" "}
                                        <span id="chatId2">Ping us</span> or
                                        drop us a line at sales@superops.com.
                                      </p>
                                    </span>
                                  </Col>
                                </Row>
                              </div>
                            </Col>
                          </Row>
                        </Container>
                      </div>
                    </div>

                    <CTAThankYou />
                  </Frame>
                </>
              )
            })}
          </div>
        )}
      />
    </>
  )
}

export default PricingPackage2024
