import { useEffect } from "react"

/**
 * NOTE: This hook is used to show Intercom on click of a button or element.
 * @param {string} selectors - The selectors to which the click event is attached.
 * @returns null
 */
export const useIntercomShow = (selectors = "") => {
  const intercomShow = () => {
    if (typeof window.Intercom !== "undefined") {
      window.Intercom("show")
    }
  }

  useEffect(() => {
    const intercomSelectors = document.querySelectorAll(selectors)

    intercomSelectors.forEach(selector => {
      selector.addEventListener("click", e => {
        e.stopPropagation()
        intercomShow()
      })
    })

    return () => {
      intercomSelectors.forEach(selector => {
        selector.addEventListener("click", () => {
          intercomShow()
        })
      })
    }
  }, [])
}
